import * as React from "react";
import {
    ArrayInput, required, useTranslate, SimpleFormIterator, BooleanInput, FormDataConsumer
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import {
    KuggarTextInput
} from "../../shared";

const CardProcessorPolicyCurrencyControls = () => {
    const translate = useTranslate();
    const formContext = useFormContext();

    const updateForm = (value) => {
        if (value === false) {
            formContext.setValue('cardProcessorPolicy.currencyControls', null);
        }
        return value;
    };

    return (
        <>
            <BooleanInput
                source="cardProcessorPolicyHasCurrencyControls"
                format={updateForm}
                label={translate('kuggar.card_processor_policy.has_currency_controls')} />
            <FormDataConsumer>
                {({ formData }) => formData.cardProcessorPolicyHasCurrencyControls
                    ?
                    <>
                        <ArrayInput source="cardProcessorPolicy.currencyControls.allowedCurrencyCodes" label={translate('kuggar.card_processor_policy.allowed_currency_codes')}>
                            <SimpleFormIterator inline>
                                <KuggarTextInput source="" label={translate('kuggar.card_processor_policy.currency_code')} fullWidth={true} validate={[required()]} />
                            </SimpleFormIterator>
                        </ArrayInput>
                        <ArrayInput source="cardProcessorPolicy.currencyControls.blockedCurrencyCodes" label={translate('kuggar.card_processor_policy.blocked_currency_codes')}>
                            <SimpleFormIterator inline>
                                <KuggarTextInput source="" label={translate('kuggar.card_processor_policy.currency_code')} fullWidth={true} validate={[required()]} />
                            </SimpleFormIterator>
                        </ArrayInput>
                    </>
                    : null
                }
            </FormDataConsumer>
        </>
    );
};

const CardProcessorPolicyCountryControls = () => {
    const translate = useTranslate();
    const formContext = useFormContext();

    const updateForm = (value) => {
        if (value === false) {
            formContext.setValue('cardProcessorPolicy.countryControls', null);
        }
        return value;
    };

    return (
        <>
            <BooleanInput
                source="cardProcessorPolicyHasCountryControls"
                format={updateForm}
                label={translate('kuggar.card_processor_policy.has_country_controls')} />
            <FormDataConsumer>
                {({ formData }) => formData.cardProcessorPolicyHasCountryControls
                    ?
                    <>
                        <ArrayInput source="cardProcessorPolicy.countryControls.allowedCountryCodes" label={translate('kuggar.card_processor_policy.allowed_country_codes')}>
                            <SimpleFormIterator inline>
                                <KuggarTextInput source="" label={translate('kuggar.card_processor_policy.country_code')} fullWidth={true} validate={[required()]} />
                            </SimpleFormIterator>
                        </ArrayInput>
                        <ArrayInput source="cardProcessorPolicy.countryControls.blockedCountryCodes" label={translate('kuggar.card_processor_policy.blocked_country_codes')}>
                            <SimpleFormIterator inline>
                                <KuggarTextInput source="" label={translate('kuggar.card_processor_policy.country_code')} fullWidth={true} validate={[required()]} />
                            </SimpleFormIterator>
                        </ArrayInput>
                    </>
                    : null
                }
            </FormDataConsumer>
        </>
    );
};

const CardProcessorPolicyMerchantCategoryControls = () => {
    const translate = useTranslate();
    const formContext = useFormContext();

    const updateForm = (value) => {
        if (value === false) {
            formContext.setValue('cardProcessorPolicy.merchantCategoryControls', null);
        }
        return value;
    };

    return (
        <>
            <BooleanInput
                source="cardProcessorPolicyHasMerchantCategoryControls"
                format={updateForm}
                label={translate('kuggar.card_processor_policy.has_merchant_category_controls')} />
            <FormDataConsumer>
                {({ formData }) => formData.cardProcessorPolicyHasMerchantCategoryControls
                    ?
                    <>
                        <ArrayInput source="cardProcessorPolicy.merchantCategoryControls.allowedMerchantCategories" label={translate('kuggar.card_processor_policy.allowed_merchant_categories')}>
                            <SimpleFormIterator inline>
                                <KuggarTextInput source="" label={translate('kuggar.card_processor_policy.merchant_category')} fullWidth={true} validate={[required()]} />
                            </SimpleFormIterator>
                        </ArrayInput>
                        <ArrayInput source="cardProcessorPolicy.merchantCategoryControls.blockedMerchantCategories" label={translate('kuggar.card_processor_policy.blocked_merchant_categories')}>
                            <SimpleFormIterator inline>
                                <KuggarTextInput source="" label={translate('kuggar.card_processor_policy.merchant_category')} fullWidth={true} validate={[required()]} />
                            </SimpleFormIterator>
                        </ArrayInput>
                    </>
                    : null
                }
            </FormDataConsumer>
        </>
    );
};

const CardProcessorPolicyMerchantIdentifierControls = () => {
    const translate = useTranslate();
    const formContext = useFormContext();

    const updateForm = (value) => {
        if (value === false) {
            formContext.setValue('cardProcessorPolicy.merchantIdentifierControls', null);
        }
        return value;
    };

    return (
        <>
            <BooleanInput
                source="cardProcessorPolicyHasMerchantIdentifierControls"
                format={updateForm}
                label={translate('kuggar.card_processor_policy.has_merchant_identifier_controls')} />
            <FormDataConsumer>
                {({ formData }) => formData.cardProcessorPolicyHasMerchantIdentifierControls
                    ?
                    <>
                        <ArrayInput source="cardProcessorPolicy.merchantIdentifierControls.allowedMerchantIdentifiers" label={translate('kuggar.card_processor_policy.allowed_merchant_identifiers')}>
                            <SimpleFormIterator inline>
                                <KuggarTextInput source="" label={translate('kuggar.card_processor_policy.merchant_identifier')} fullWidth={true} validate={[required()]} />
                            </SimpleFormIterator>
                        </ArrayInput>
                        <ArrayInput source="cardProcessorPolicy.merchantIdentifierControls.blockedMerchantIdentifiers" label={translate('kuggar.card_processor_policy.blocked_merchant_identifiers')}>
                            <SimpleFormIterator inline>
                                <KuggarTextInput source="" label={translate('kuggar.card_processor_policy.merchant_identifier')} fullWidth={true} validate={[required()]} />
                            </SimpleFormIterator>
                        </ArrayInput>
                    </>
                    : null
                }
            </FormDataConsumer>
        </>
    );
};

const CardProcessorPolicyStep = props => {
    const translate = useTranslate();

    return (
        <>
            <KuggarTextInput
                source="cardProcessorPolicy.Description"
                label={translate('kuggar.shared.description')}
                fullWidth={true}
                validate={[required()]} />

            <CardProcessorPolicyCurrencyControls/>
            <CardProcessorPolicyCountryControls />
            <CardProcessorPolicyMerchantCategoryControls/>
            <CardProcessorPolicyMerchantIdentifierControls/>

        </>
    );
};

export default CardProcessorPolicyStep;