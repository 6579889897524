import * as React from "react";
import {
    List, useTranslate, Datagrid, TextField, BooleanField,
    Show, SimpleShowLayout, Title, NumberField, useRecordContext,
    Labeled, DateField, useNotify, useRedirect, Create,
    SimpleForm, Edit, EditButton,
    TopToolbar
} from "react-admin";
import {
    TableFilter, TablePagination, DateTimeLocale,
    CreateToolbar, HasCreatePermission, UpdateToolbar,
    HasUpdatePermission
} from "../../shared";
import PermissionsCreateUpdate from "../../components/PermissionsCreateUpdate";
import FundingAccountCreateForm from "../../components/issuer-processor/FundingAccountCreateForm"

export const FundingAccountShow = () => {
    const translate = useTranslate();

    const Actions = () => {
        const data = useRecordContext();
        return (
            <TopToolbar>
                {HasUpdatePermission('IssuerProcessor', '/funding_accounts') ?
                    <EditButton
                        label={translate('kuggar.funding_accounts.update_funding_account')}
                        record={data} />
                    : null}
            </TopToolbar>
        );
    };

    const AccountDetailsView = () => {
        const record = useRecordContext();

        if (record.creditAccountDetails) {
            return (
                <div>
                    <div>
                        <Labeled label={translate('kuggar.funding_accounts.credit_account_number')}>
                            <TextField source="creditAccountDetails.accountNumber" />
                        </Labeled>
                    </div>
                    <div>
                        <Labeled label={translate('kuggar.funding_accounts.credit_account_bin')}>
                            <TextField source="creditAccountDetails.bin" />
                        </Labeled>
                    </div>
                    <div>
                        <Labeled label={translate('kuggar.funding_accounts.credit_account_expiry_date')}>
                            <DateField source="creditAccountDetails.expiryDate" locales={DateTimeLocale} />    
                        </Labeled>
                    </div>
                </div>
            )
        }
        else {
            return (
                null
            )
        }
    };

    return (
        <Show title={<Title />} actions={<Actions />} >
            <SimpleShowLayout>
                <TextField source="id" label={translate('kuggar.shared.id')} />
                <TextField source="type" label={translate('kuggar.shared.type')} />
                <TextField source="description" label={translate('kuggar.shared.description')} />
                <TextField source="currencyCode" label={translate('kuggar.shared.currency_code')} />
                <NumberField source="authorisationLimit" label={translate('kuggar.funding_accounts.auth_limit')} />
                <NumberField source="currentAuthorisedBalance" label={translate('kuggar.funding_accounts.current_auth_balance')} />
                <BooleanField source="active" label={translate('kuggar.shared.active')} />
                <AccountDetailsView />
            </SimpleShowLayout>
        </Show>
    );
};

export const FundingAccountList = () => {
    const translate = useTranslate();

    return (
        <List
            filters={<TableFilter />}
            pagination={<TablePagination />}
            perPage={10}
            hasCreate={HasCreatePermission('IssuerProcessor', '/funding_accounts')}        >
            <Datagrid rowClick="show" bulkActionButtons={false}>
                <TextField source="id" label={translate('kuggar.shared.id')} />
                <TextField source="type" label={translate('kuggar.shared.type')} />
                <TextField source="description" label={translate('kuggar.shared.description')} />
                <TextField source="currencyCode" label={translate('kuggar.shared.currency_code')} />
                <BooleanField source="active" label={translate('kuggar.shared.active')} />
            </Datagrid>
        </List>
    );
};

export const FundingAccountCreate = () => {
    const translate = useTranslate();
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = (data) => {
        notify(`${translate('kuggar.funding_accounts.on_success_notify')}`);
        redirect(`/ip_funding_account/${data.id}/show`);
    };

    const onError = (data) => {
        notify(data.body.error, { type: 'error' });
    };

    return (
        <Create mutationOptions={{ onSuccess, onError }}>
            <SimpleForm toolbar={<CreateToolbar />}>
                <FundingAccountCreateForm source="" />
                <PermissionsCreateUpdate />
            </SimpleForm>
        </Create>
    );
};

export const FundingAccountEdit = () => {

    return (
        <Edit mutationMode="optimistic">
            <SimpleForm toolbar={<UpdateToolbar />}>
                <FundingAccountCreateForm source="" />
                <PermissionsCreateUpdate />
            </SimpleForm>
        </Edit>
    );
};