import {
	List, Show, Edit, Create, Loading, Error,
	Datagrid, TabbedShowLayout, Tab, SimpleForm,
	TextField, FunctionField, ReferenceField,
	TextInput, SelectInput, SelectArrayInput,
	TopToolbar, EditButton, DeleteButton, Button, Labeled,
	required, useTranslate, useNotify, useRedirect, useRecordContext, useGetList, ExportButton
} from 'react-admin';
import {
    TableFilter,
    TablePagination,
    UpdateToolbar,
    HasCreatePermission,
    HasUpdatePermission,
    HasDeletePermission,
    TextFieldWithCopyButton, KuggarTextInput, kuggarExporter, CreateToolbar
} from '../shared';
import {useNavigate, useLocation} from 'react-router-dom';
import {useEffect, useState} from "react";

export const ShareList = () => {
    const translate = useTranslate();

    
    const ListActions = () => (
        <TopToolbar>        
            <ExportButton/>
        </TopToolbar>
    );

    const filters = [
        <TextInput label={translate('kuggar.virtual_card_share.query')} source="q" alwaysOn/>,
        <SupplierSelectInput validate='false' isFilter={true} alwaysOn />
    ];

    return (
        <List
            exporter={kuggarExporter}
            filters={filters}
            pagination={<TablePagination />}
            perPage={10}
            actions={<ListActions />}
            sort={{ field: 'supplier', order: 'ASC' }}>
            <Datagrid rowClick="show" bulkActionButtons={false}>
                <TextField source="id" label={translate('kuggar.shared.id')} />
                <TextField source="virtualCardId" label={translate('kuggar.virtual_card_share.virtual_card')} />
                <ReferenceField source="supplierId" label={translate('kuggar.supplier.supplier')} reference="supplier" link="show">
                    <TextField source="name"/>
                </ReferenceField>
                <TextField source="expiresAt" label={translate('kuggar.virtual_card_share.expiresAt')} />
            </Datagrid>
        </List>
    );
};

export const ShareShow = () => {
    const translate = useTranslate();
    const notify = useNotify();
    const redirect = useRedirect();

    const Title = () => {
            const record = useRecordContext();
            return <span>Virtual Card Share - ID {record ? record.id : ''}</span>;
        };

    const onSuccess = () => {
        notify(`${translate('kuggar.virtual_card_share.on_delete_success_notify')}`);
        redirect(`/virtual_card_share`);
    };
        
    const onError = (data) => {
        notify(`${translate('kuggar.virtual_card_share.on_delete_error_notify')}: ${data.body.error}`, { type: 'error' });
    };

    const Actions = () => {
            const data = useRecordContext();
            return (
                <TopToolbar>
                    {HasUpdatePermission('VirtualCards', '/virtual_card_share') ?
                        <EditButton
                            label={translate('kuggar.virtual_card_share.update')}
                            record={data}
                        />
                        : null}
                    {HasDeletePermission('VirtualCards', '/virtual_card_share') ?
                        <DeleteButton
                           label={translate('kuggar.virtual_card_share.delete')}
                           record={data}
                           mutationMode="optimistic"
                           mutationOptions={{ onSuccess, onError }}
                           confirmTitle="Delete Virtual Card Share"
                           confirmContent={`Are you sure you want to delete Virtual Card Share with ID ${data ? data.id : ''}? This action cannot be undone.`} />
                        : null}
                </TopToolbar>
            );
        };

    return (
        <Show title={<Title />} actions={<Actions />}>
            <TabbedShowLayout toolbar={<UpdateToolbar/>}>
                <Tab label={translate('kuggar.shared.tab_summary')}>
                    <TextFieldWithCopyButton source="id" label={translate('kuggar.shared.id')} disabled /> 
                    <TextField source="virtualCardId" label={translate('kuggar.virtual_card_share.virtual_card')} />
                    <ReferenceField source="supplierId" label={translate('kuggar.supplier.supplier')} reference="supplier" link="show">
                        <TextField source="name"/>
                    </ReferenceField>
                    <TextField source="expiresAt" label={translate('kuggar.virtual_card_share.expiresAt')} />
                    <TextField source="maximumAccessCount" label={translate('kuggar.virtual_card_share.access')} />
                    <TextField source="passcode" label={translate('kuggar.virtual_card_share.passcode')} />
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
 };

export const ShareEdit = () => {
    const translate = useTranslate();   

    return (
        <Edit mutationMode="optimistic">
            <SimpleForm toolbar={<UpdateToolbar/>}>
                <KuggarTextInput source="id" label={translate('kuggar.shared.id')} fullWidth={true} validate={[required()]} />
                <TextInput source="virtualCardId" label={translate('kuggar.virtual_card_share.virtual_card')} disabled fullWidth={true}/>                
                <SupplierSelectInput />
                <SelectInput source="expiryDays" label={translate('kuggar.virtual_card_share.expiry')} choices={expiryChoices} optionText="label" optionValue="_id" fullWidth={true} validate={[required()]} />
                <KuggarTextInput source="maximumAccessCount" label={translate('kuggar.virtual_card_share.access')} defaultValue='2' fullWidth={true} />
                <KuggarTextInput source="passcode" label={translate('kuggar.virtual_card_share.passcode')} fullWidth={true} />
            </SimpleForm>
        </Edit>
    )
}

export const ShareCreate = () => {
    const translate = useTranslate();
    const notify = useNotify();
    const redirect = useRedirect();
    const location = useLocation();
    const [virtualCardId, setVirtualCardId] = useState(null);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const id = params.get('cardId');        
        setVirtualCardId(id);
    }, [location]);

    const onSuccess = (data) => {
        notify(`${translate('kuggar.virtual_card_share.create_success')}`);
        redirect(`/virtual_card_share/${data.id}/show`);
    };

    const onError = (data) => {
        notify(data.body.error, {type: 'error'});
    };

    const createDefaultValues = () => ({ 'virtualCardId': virtualCardId, 'expiryDays': '3', 'maximumAccessCount': '2'});

    return (
        <Create mutationMode="optimistic" mutationOptions={{ onSuccess, onError }}>
            <SimpleForm toolbar={<CreateToolbar/>} defaultValues={createDefaultValues}>
                {virtualCardId && (
                                    <TextInput
                                        source="virtualCardId"
                                        label={translate('kuggar.virtual_card_share.virtual_card')}
                                        defaultValue={virtualCardId}
                                        disabled
                                    />
                                )}
                <SupplierSelectInput />
                <SelectInput source="expiryDays" label={translate('kuggar.virtual_card_share.expiry')} choices={expiryChoices} optionText="label" optionValue="_id" fullWidth={true} validate={[required()]} />
                <KuggarTextInput source="maximumAccessCount" label={translate('kuggar.virtual_card_share.access')} fullWidth={true} validate={[required()]}/>
                <KuggarTextInput source="passcode" label={translate('kuggar.virtual_card_share.passcode')} fullWidth={true} validate={[required()]} />
            </SimpleForm>
        </Create>
    )
}

const SupplierSelectInput = props => {
    const { isFilter, defaultValue } = props;
    const translate = useTranslate();
    const {data, isLoading, error} = useGetList(
        'supplier',
        {
            filter: {},
            pagination: {
                page: 1,
                perPage: 1000
            },
            sort: {
                field: 'name',
                order: 'ASC'
            }
        }
    );
    if (isLoading) return <Loading/>;
    if (error) return <Error/>;
    if (!data) return null;

    return (
        <SelectInput source="supplierId" label={translate('kuggar.supplier.supplier')} validate={isFilter === true ? null : [required()]}
                     choices={data} optionText="name" optionValue="id" defaultValue={defaultValue}/>
    );
};

const expiryChoices = [
    { _id: '1', label: '1 Day' },
    { _id: '3', label: '3 Days' },
    { _id: '5', label: '5 Days' },
    { _id: '7', label: '7 Days' },
];


