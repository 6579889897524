import * as React from "react";
import {
    List, useTranslate, Datagrid, TextField, BooleanField,
    Show, SimpleShowLayout, DateField, Title, 
    NumberField, useRecordContext, Labeled
} from "react-admin";
import {
    TableFilter, TablePagination, DateTimeLocale
} from "../../shared";
import TextArrayField from "../../components/TextArrayField"

export const SettlementsRunList = () => {
    const translate = useTranslate();

    return (
        <List
            filters={<TableFilter />}
            pagination={<TablePagination />}
            perPage={10}>
            <Datagrid rowClick="show" bulkActionButtons={false}>
                <TextField source="id" label={translate('kuggar.shared.id')}  />
                <TextField source="type" label={translate('kuggar.shared.type')} />
                <TextField source="downloadedFileName" label={translate('kuggar.settlements_runs.dl_file_name')} />
                <DateField source="createdUtcTimestamp" label={translate('kuggar.settlements_runs.dl_timestamp')} showTime
                    locales={DateTimeLocale} />
                <BooleanField source="isProcessed" label={translate('kuggar.settlements_runs.is_processed')} />
                <BooleanField source="isUploaded" label={translate('kuggar.settlements_runs.is_uploaded')} />
            </Datagrid>
        </List>
    );
};

export const SettlementsRunShow = () => {
    const translate = useTranslate();

    const ProcessedDetailsView = () => {
        const record = useRecordContext();

        if (record.isProcessed === true) {
            return (
                <div>
                    <Labeled label={translate('kuggar.settlements_runs.processing_time')}>
                        <NumberField source="processingTimeMs" />
                    </Labeled>
                </div>
            )
        }
        else {
            return (
                <div>
                    <Labeled label={translate('kuggar.settlements_runs.exception')}>
                        <TextField source="exceptionMessage" />   
                    </Labeled>
                </div>
            )
        }
    };

    const UploadedDetailsView = () => {
        const record = useRecordContext();

        if (record.isUploaded === true) {
            return (
                <div>
                    <div>
                        <Labeled label={translate('kuggar.settlements_runs.processed_file_names')}>
                            <TextArrayField source="processedFileNames" />
                        </Labeled>
                    </div>
                    <div>
                        <Labeled label={translate('kuggar.settlements_runs.upload_timestamp')}>
                            <DateField source="uploadedUtcTimestamp" showTime locales={DateTimeLocale} />                        
                        </Labeled>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div>
                    <Labeled label={translate('kuggar.settlements_runs.failed_upload_msg')}>
                        <TextField source="failedUploadMessage" />
                    </Labeled>
                </div>
            )
        }
    };

    return (
        <Show title={<Title />} >
            <SimpleShowLayout>
                <TextField source="id" label={translate('kuggar.shared.id')} />
                <TextField source="type" label={translate('kuggar.shared.type')} />    

                <TextField source="downloadedFileName" label={translate('kuggar.settlements_runs.dl_file_name')} />
                <DateField source="createdUtcTimestamp" label={translate('kuggar.settlements_runs.dl_timestamp')} showTime
                    locales={DateTimeLocale} sortable={false} />

                <BooleanField source="isProcessed" label={translate('kuggar.settlements_runs.is_processed')} />
                <ProcessedDetailsView/>

                <BooleanField source="isUploaded" label={translate('kuggar.settlements_runs.is_uploaded')} />
                <UploadedDetailsView/>

            </SimpleShowLayout>
        </Show>
    );
}