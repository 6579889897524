import * as React from "react";
import {
    SelectInput, required, useTranslate
} from 'react-admin';
import {
    KuggarTextInput
} from "../../shared";

const SchemeStep = props => {
    const translate = useTranslate();

    return (
        <>
            <SelectInput source="scheme.type"
                validate={[required()]}
                label={translate('kuggar.shared.type')}
                fullWidth={true}
                choices={[
                    { id: 'UATP', name: 'UATP' },
                    { id: 'Visa', name: 'Visa' },
                    { id: 'Mastercard', name: 'Mastercard' }
                ]}
            />
            <KuggarTextInput source="scheme.description"
                validate={[required()]}
                label={translate('kuggar.shared.description')}
                fullWidth={true} />
        </>
    );
};

export default SchemeStep;