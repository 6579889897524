import {
    Button, Create, Datagrid, Edit, EditButton, Error, FormDataConsumer,
    List, Loading, ReferenceField, required,
    SelectInput, Show, SimpleForm, Tab, TabbedShowLayout, Labeled, FunctionField,
    TextField, TextInput, TopToolbar, useGetList, useListContext, useNotify,
    useRecordContext, useRedirect,
    useTranslate
} from "react-admin";
import * as React from "react";
import {useNavigate, useLocation} from 'react-router-dom';
import {useEffect, useState} from "react";
import { TableFilter, TablePagination, CreateToolbar, UpdateToolbar, KuggarTextInput} from '../shared';
import AddIcon from '@mui/icons-material/Add';

export const SupplierList = () => {
    const translate = useTranslate();
    const navigate = useNavigate();

    const handleCreateClick = () => {
        navigate(`/supplier/create`);
    }

    const ListActions = () => {
        return (
            <TopToolbar>
                <Button label="Create" onClick={handleCreateClick} startIcon={<AddIcon/>}/>
            </TopToolbar>
        )
    }

    return (
        <List actions={<ListActions/>} filters={<TableFilter />} pagination={<TablePagination />} perPage={20} sort={{ field: 'name', order: 'ASC' }}>
            <Datagrid bulkActionButtons={false}>
                <TextField source="id" label={translate('kuggar.shared.id')} /> 
                <TextField source="name" label={translate('kuggar.shared.name')} />
                <TextField source="recipient" label={translate('kuggar.supplier.recipient')} />                
            </Datagrid>
        </List>
    );
};

export const SupplierCreate = () => {
    const translate = useTranslate();
    const notify = useNotify();
    const redirect = useRedirect();    

    const onSuccess = (data) => {
        notify(`${translate('kuggar.supplier.success_notify')}`);
        redirect(`/supplier`);
    };

    const onError = (data) => {
        notify(data.body.error, {type: 'error'});
    };

    return (
        <Create mutationOptions={{onSuccess, onError}}>
            <SimpleForm toolbar={<CreateToolbar/>}>                                
                <KuggarTextInput source="name" label={translate('kuggar.shared.name')} fullWidth={true} validate={[required()]} />
                <KuggarTextInput source="recipient" label={translate('kuggar.notifications.recipient')} fullWidth={true} validate={[required()]} />                
            </SimpleForm>
        </Create>
    )
}

