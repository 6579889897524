import {
    required, useTranslate, NumberInput, number,
    minValue, SelectInput, DateInput, TextInput
} from 'react-admin';
import {
    KuggarTextInput
} from "../../shared";

const FundingAccountCreditDetails = (props) => {
    const translate = useTranslate();
    const { source } = props;

    const validateCreditAccountNumber = [required(), number('Must be numeric value')];
    const validateCreditAccountBin = [required(), number('Must be numeric value')];

    return (
        <>
            <KuggarTextInput source={source + 'creditAccountDetails.accountNumber'}
                validate={validateCreditAccountNumber}
                label={translate('kuggar.funding_accounts.credit_account_number')}
                fullWidth={true} />
            <KuggarTextInput source={source + 'creditAccountDetails.bin'}
                validate={validateCreditAccountBin}
                label={translate('kuggar.funding_accounts.credit_account_bin')}
                fullWidth={true} />
            <DateInput source={source + 'creditAccountDetails.expiryDate'}
                label={translate('kuggar.funding_accounts.credit_account_expiry_date')}
                fullWidth={true}
                validate={[required()]} />
        </>
    );
};

const FundingAccountCreateForm = props => {
    const { source } = props;
    const translate = useTranslate();

    const validateAuthLimit = [required(), number('Must be numeric value'), minValue(1, 'Must be positive number')];

    return (
        <>
            <TextInput source={source + 'type'}
                style={{ display: 'none' }}
                defaultValue="Credit" />
            <KuggarTextInput source={source + 'description'}
                validate={[required()]}
                label={translate('kuggar.shared.description')}
                fullWidth={true} />
            <NumberInput source={source + 'authorisationLimit'}
                validate={validateAuthLimit}
                label={translate('kuggar.funding_accounts.auth_limit')}
                fullWidth={true} />
            <SelectInput source={source + 'currencyCode'}
                validate={[required()]}
                label={translate('kuggar.shared.currency_code')}
                fullWidth={true}
                choices={[
                    { id: 'USD', name: 'USD' },
                    { id: 'AUD', name: 'AUD' }
                ]}
            />

            <FundingAccountCreditDetails source={source} />
        </>
    );
};

export default FundingAccountCreateForm;