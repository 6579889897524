import { Environment } from "./configProvider";

const cloudRegion = localStorage.getItem('cloud-region');
const cloudProvider = localStorage.getItem('cloud-provider')
    ? localStorage.getItem('cloud-provider')
    : (Environment.MultiRegion === true ? Object.keys(Environment.API.Platform)[0] : "Azure");

const vcApiUrl = Environment.MultiRegion === true 
    ? (Environment.API.VirtualCards[cloudProvider][cloudRegion]
        ? Environment.API.VirtualCards[cloudProvider][cloudRegion]
        : Environment.API.VirtualCards[Object.keys(Environment.API.VirtualCards)[0]][Object.keys(Environment.API.VirtualCards[Object.keys(Environment.API.VirtualCards)[0]])[0]])
    : Environment.API.VirtualCards;

function getIssuerAccount(id, onSuccess, onError, onFinal) {
    let getUrl = `${vcApiUrl}/issuer_account?issuerAccountId=${id}`;
    return fetch(getUrl, {
        method: 'get',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        credentials: "include"
    })
    .then(resp => resp.json())
    .then(data => onSuccess(data))
    .catch(onError)
    .finally(onFinal);
}

function addAccountDefinedData(data, onSuccess, onError, onFinal) {
    let putUrl = `${vcApiUrl}/issuer_account/add_defined_data`;

    return fetch(putUrl, {
        method: 'put',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        credentials: "include"
    })
    .then(resp => resp.json())
    .then(data => onSuccess(data))
    .catch(onError)
    .finally(onFinal);
}

const ApiClient = { getIssuerAccount, addAccountDefinedData };
export default ApiClient;