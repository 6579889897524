import * as React from "react";
import {
    SelectInput, required, useTranslate, ArrayInput, number, minValue,
    NumberInput, SimpleFormIterator, FormDataConsumer, PasswordInput, BooleanInput
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import {
    KuggarTextInput
} from "../../shared";

const CardIssuerTemplateMaxActiveDate = () => {
    const translate = useTranslate();
    const inputStyle = { width: '100%' };
    const formContext = useFormContext();

    const updateForm = (value) => {
        if (value === false) {
            formContext.setValue('cardIssuerTemplate.maxActiveToDate', null);
        }
        return value;
    };

    const validateValue = [required(), number('Must be numeric value'), minValue(1, 'Must be positive number')];

    return (
        <>
            <BooleanInput
                source="cardIssuerTemplateHasMaxActiveToDate"
                format={updateForm}
                label={translate('kuggar.card_issuer_template.has_max_active_to_date')} />
            <FormDataConsumer>
                {({ formData }) => formData.cardIssuerTemplateHasMaxActiveToDate
                    ?
                    <>
                        <SelectInput source="maxActiveToDateType"
                            validate={[required()]}
                            label={translate('kuggar.card_issuer_template.max_active_to_date')}
                            fullWidth={true}
                            defaultValue="MonthsFromNow"
                            choices={[
                                { id: 'DaysFromNow', name: 'Days from card issue date' },
                                { id: 'MonthsFromNow', name: 'Months from card issue date' }
                            ]}
                        />
                        <FormDataConsumer>
                            {({ formData }) => formData.maxActiveToDateType === 'DaysFromNow'
                                ? <NumberInput
                                    source="cardIssuerTemplate.maxActiveToDate.daysFromNow"
                                    label={translate('kuggar.card_issuer_template.expiry_days')}
                                    style={inputStyle}
                                    validate={validateValue} />
                                : <NumberInput
                                    source="cardIssuerTemplate.maxActiveToDate.monthsFromNow"
                                    label={translate('kuggar.card_issuer_template.expiry_months')}
                                    style={inputStyle}
                                    validate={validateValue} />
                            }
                        </FormDataConsumer>
                    </>
                    : null
                }
            </FormDataConsumer>
        </>
    );
};

const CardIssuerTemplateTolerance = () => {
    const translate = useTranslate();
    const inputStyle = { width: '100%' };
    const formContext = useFormContext();

    const updateForm = (value) => {
        if (value === false) {
            formContext.setValue('cardIssuerTemplate.tolerance', null);
        }
        return value;
    };

    const validateValue = [required(), number('Must be numeric value'), minValue(1, 'Must be positive number')];

    return (
        <>
            <BooleanInput
                source="cardIssuerTemplateHasTolerance"
                format={updateForm}
                label={translate('kuggar.card_issuer_template.has_tolerance')} />
            <FormDataConsumer>
                {({ formData }) => formData.cardIssuerTemplateHasTolerance
                    ?
                    <>
                        <SelectInput source="toleranceType"
                            validate={[required()]}
                            label={translate('kuggar.card_issuer_template.tolerance_type')}
                            fullWidth={true}
                            defaultValue="Percent"
                            choices={[
                                { id: 'Percent', name: 'Percent' },
                                { id: 'FixedAmount', name: 'Fixed Amount' }
                            ]}
                        />
                        <FormDataConsumer>
                            {({ formData }) => formData.toleranceType === 'Percent'
                                ? <NumberInput
                                    source="cardIssuerTemplate.tolerance.percent"
                                    label={translate('kuggar.card_issuer_template.tolerance_percent')}
                                    style={inputStyle}
                                    validate={validateValue} />
                                : <NumberInput
                                    source="cardIssuerTemplate.tolerance.fixedAmount"
                                    label={translate('kuggar.card_issuer_template.tolerance_fixed_amount')}
                                    style={inputStyle}
                                    validate={validateValue} />
                            }
                        </FormDataConsumer>
                    </>
                    : null
                }
            </FormDataConsumer>
        </>
    );
};

const CardIssuerTemplateCurrencyControls = () => {
    const translate = useTranslate();
    const formContext = useFormContext();

    const updateForm = (value) => {
        if (value === false) {
            formContext.setValue('cardIssuerTemplate.currencyControls', null);
        }
        return value;
    };

    return (
        <>
            <BooleanInput
                source="cardIssuerTemplateHasCurrencyControls"
                format={updateForm}
                label={translate('kuggar.card_issuer_template.has_currency_controls')} />
            <FormDataConsumer>
                {({ formData }) => formData.cardIssuerTemplateHasCurrencyControls
                    ?
                    <>
                        <ArrayInput source="cardIssuerTemplate.currencyControls.allowedCurrencyCodes" label={translate('kuggar.card_processor_policy.allowed_currency_codes')}>
                            <SimpleFormIterator inline>
                                <KuggarTextInput source="" label={translate('kuggar.card_processor_policy.currency_code')} fullWidth={true} validate={[required()]} />
                            </SimpleFormIterator>
                        </ArrayInput>
                        <ArrayInput source="cardIssuerTemplate.currencyControls.blockedCurrencyCodes" label={translate('kuggar.card_processor_policy.blocked_currency_codes')}>
                            <SimpleFormIterator inline>
                                <KuggarTextInput source="" label={translate('kuggar.card_processor_policy.currency_code')} fullWidth={true} validate={[required()]} />
                            </SimpleFormIterator>
                        </ArrayInput>
                    </>
                    : null
                }
            </FormDataConsumer>
        </>
    );
};

const CardIssuerTemplateWebhook = () => {
    const translate = useTranslate();
    const formContext = useFormContext();

    const updateForm = (value) => {
        if (value === false) {
            formContext.setValue('cardIssuerTemplate.webhookConfig', null);
        }
        return value;
    };

    return (
        <>
            <BooleanInput
                source="cardIssuerTemplateHasWebhook"
                format={updateForm}
                label={translate('kuggar.card_issuer_template.has_webhook')} />
            <FormDataConsumer>
                {({ formData }) => formData.cardIssuerTemplateHasWebhook
                    ?
                    <>
                        <KuggarTextInput
                            source="cardIssuerTemplate.webhookConfig.webhookUrl"
                            label={translate('kuggar.card_issuer_template.webhook_config_url')}
                            validate={[required()]}
                            fullWidth={true} />
                        <KuggarTextInput
                            source="cardIssuerTemplate.webhookConfig.webhookUsername"
                            label={translate('kuggar.card_issuer_template.webhook_config_username')}
                            validate={[required()]}
                            fullWidth={true} />
                        <PasswordInput
                            source="cardIssuerTemplate.webhookConfig.webhookPassword"
                            label={translate('kuggar.card_issuer_template.webhook_config_password')}
                            validate={[required()]}
                            fullWidth={true} />
                    </>
                    : null
                }
            </FormDataConsumer>
        </>
    );
};

const CardIssuerTemplateMetadataConfig = () => {
    const translate = useTranslate();

    return (
        <>
            <ArrayInput source="cardIssuerTemplate.metadataConfig" label={translate('kuggar.metadata_configs.metadata_config')}>
                <SimpleFormIterator inline>
                    <KuggarTextInput
                        source="label"
                        label={translate('kuggar.metadata_configs.label')}
                        fullWidth={true}
                        validate={[required()]} />
                    <SelectInput source="metadataType" bel={translate('kuggar.metadata_configs.metadata_type')} validate={[required()]} choices={[
                        { id: 'String', name: 'String' },
                        { id: 'Number', name: 'Number' },
                        { id: 'Boolean', name: 'Boolean' }]}
                    />
                    <KuggarTextInput
                        source="defaultValue"
                        label={translate('kuggar.metadata_configs.default_value')}
                        fullWidth={true}
                        validate={[required()]} />
                </SimpleFormIterator>
            </ArrayInput>
        </>
    );
};

const CardIssuerTemplateStep = props => {
    const translate = useTranslate();
    const inputStyle = { width: '100%' };

    const validatePositiveNumber = [required(), number('Must be numeric value'), minValue(1, 'Must be positive number')];

    return (
        <>
            <KuggarTextInput
                source="cardIssuerTemplate.description"
                label={translate('kuggar.shared.description')}
                fullWidth={true}
                validate={[required()]} />
            <NumberInput
                source="cardIssuerTemplate.maxAuthorisations"
                label={translate('kuggar.card_issuer_template.max_authorisations')}
                style={inputStyle}
                validate={validatePositiveNumber} />
            <NumberInput
                source="cardIssuerTemplate.maxLimit"
                label={translate('kuggar.card_issuer_template.max_limit')}
                style={inputStyle}
                validate={validatePositiveNumber} />
            <NumberInput
                source="cardIssuerTemplate.expiryDateProjectionMonths"
                label={translate('kuggar.card_issuer_template.projected_expiry_date')}
                style={inputStyle}
                validate={validatePositiveNumber} />
            <CardIssuerTemplateMetadataConfig />
            <BooleanInput
                source="cardIssuerTemplate.hasCvv"
                label={translate('kuggar.card_issuer_template.has_cvv')} />
            <CardIssuerTemplateMaxActiveDate/>
            <CardIssuerTemplateTolerance/>
            <CardIssuerTemplateCurrencyControls />         
            <CardIssuerTemplateWebhook/>
        </>
    );
};

export default CardIssuerTemplateStep;