import * as React from "react";
import FundingAccountCreateForm from "../issuer-processor/FundingAccountCreateForm"

const FundingAccountStep = () => {

    return (
        <>
            <FundingAccountCreateForm source="fundingAccount." />
        </>
    );
};

export default FundingAccountStep;